import Link from "next/link";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faClone,
  faCogs,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./header.module.css";
import { useAuth } from "../lib/authUserContext";

const navList = [
  { name: "Home", href: "/", icon: faHome },
  { name: "Episodes", href: "/episodes", icon: faClone },
  // { name: "Settings", href: "/users", icon: faCogs },
];

const NavLink = ({ href, name, icon }: any) => {
  const router = useRouter();
  return (
    <div
      className={`${styles.navItem} ${
        router.asPath === href ? styles.active : styles.inactive
      }`}
    >
      <Link href={href}>
        <a title={name}>
          <FontAwesomeIcon icon={icon} />
        </a>
      </Link>
    </div>
  );
};

export default function Header({ icon, name }: any) {
  const { authUser, loading } = useAuth();
  const router = useRouter();
  const activePage =
    navList.find((item) => router.asPath === item.href) || navList[0];
  if (loading || !authUser) return null;
  return (
    <>
      <noscript>
        <style>{`.nojs-show { opacity: 1; top: 0; }`}</style>
      </noscript>
      <header className={styles.header}>
        <h2>
          <FontAwesomeIcon icon={icon || activePage.icon} />{" "}
          <span className={styles.pageTitle}>{name || activePage.name}</span>
        </h2>
        <div className={styles.signedInStatus}>
          <p className={`nojs-show`}>
            {!authUser ? (
              <>
                <span className={styles.notSignedInText}>
                  You are not signed in
                </span>
                <Link href={`/signin`}>
                  <a className={styles.buttonPrimary}>
                    <FontAwesomeIcon icon={faSignInAlt} title="Sign In" />
                  </a>
                </Link>
              </>
            ) : (
              <>
                <span className={styles.signedInText}>
                  <small>Signed in as </small>
                  <strong>
                    {authUser.name}
                    <br />(<small>{authUser.email}</small>)
                  </strong>
                </span>
                <Link href={`/signout`}>
                  <a className={styles.buttonPrimary} title="Sign out">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </a>
                </Link>
              </>
            )}
          </p>
        </div>
      </header>
      <nav className={styles.nav}>
        <div className={styles.navItems}>
          {navList.map((item) => (
            <NavLink
              key={item.href}
              href={item.href}
              name={item.name}
              icon={item.icon}
            />
          ))}
        </div>
      </nav>
    </>
  );
}

import styles from "./footer.module.css";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <ul className={styles.navItems}>
        <li className={styles.navItem}>
          <a href="https://esalad.in" target="_blank" rel="noreferrer">
            {process.env.NEXT_PUBLIC_VERCEL_ENV || "Local Development"}
            {" Version  -  "}
            {(
              process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "000000000"
            ).substr(0, 9)}
          </a>
        </li>
      </ul>
    </footer>
  );
}

import Layout from '../components/layout'
import IdleScreen from "../components/idleScreen";
import { useAuth } from '../lib/authUserContext';

export default function Page() {
  const { authUser } = useAuth();
  return (
    <Layout>
      <IdleScreen>
        <h1>Hello, {authUser.name}!</h1>
        <h3>Welcome to the Arete Quiz App</h3>
      </IdleScreen>
    </Layout>
  )
}
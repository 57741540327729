import Image from "next/image";
import Link from "next/link";

export default function AccessDenied({ reason = "login", router }: any) {
  let message;
  switch (reason) {
    case "registration":
      const redirectUrl = encodeURIComponent(router.asPath);
      message = (
        <p>
          Please complete your profile registration to continue.
          <a
            href={`/signup?redirectUrl=${redirectUrl}`}
            className="buttonPrimary"
          >
            Register
          </a>
        </p>
      );
      break;

    case "role":
      message = (
        <>
          <h1>Access Denied</h1>
          <p>You are not authorized to view this page</p>
        </>
      );
      break;

    default:
      message = (
        <p>
          Please sign in to continue
          <Link href={`/signin?redirectUrl=${redirectUrl}`}>
            <a
              className="buttonPrimary"
            >
              Sign In
            </a>
          </Link>
        </p>
      );
      break;
  }
  return (
    <section className="middle">
      <Image
        src="/img/arete_logo.svg"
        alt="Logo"
        layout="intrinsic"
        width={256}
        height={108}
      />
      {message}
    </section>
  );
}

import Header from "./header";
import Footer from "./footer";
import AccessDenied from "./access-denied";
import { useRouter } from "next/router";
import { useAuth } from "../lib/authUserContext";

interface LayoutProps {
  children: React.ReactNode;
  icon?: any;
  name?: string;
  className?: string;
}

export default function Layout({
  children,
  icon,
  name,
  className = "",
  ...props
}: LayoutProps) {
  const { authUser, loading } = useAuth();
  const router = useRouter();
  if (!loading && !authUser) {
    return <AccessDenied router={router} />;
  }
  return (
    <div className="container">
      <Header icon={icon} name={name} />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <main className={className}>{children}</main>
      )}
      <Footer />
    </div>
  );
}
